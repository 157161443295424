@import "_fontfaces";

body{
	font-size: 15px;
	font-family: 'amiri-regular', sans-serif;
}

a{
	color: inherit;
}

.rodape__title{
	font-family: 'amiri-bold', sans-serif;
	font-size: 16px;
	margin-bottom: 1em;
}
