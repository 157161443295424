.main__topo{
	.btn-search{
		margin-left: 5px;
		box-shadow: none !important;
	}

	@include media-breakpoint-up(xl) {
		width: 100%;
		max-width: map-get($container-max-widths, xl);
		padding: 20px 15px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		align-items: center;
	}

	@include media-breakpoint-only(lg) {
		width: 100%;
		max-width: map-get($container-max-widths, xl);
		padding: 20px 15px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		align-items: center;
	}

	@include media-breakpoint-only(md) {
		width: 100%;
		max-width: map-get($container-max-widths, xl);
		padding: 20px 15px;
		margin-left: auto;
		margin-right: auto;
		display: grid;
		grid-template-columns: 180px 1fr min-content min-content;

		& > *{
			align-self: center;
		}
	}

	@include media-breakpoint-down(sm) {
		position: fixed;
		left: 0;
		top: 0;
		bottom: 0;
		background: #FFF;
		width: 250px;
		height: 100%;
		overflow: auto;
		transition: all 0.6s linear;
		will-change: transform;
		transform: translateX(var(--x,-100%));
		z-index: 1000;

		&.main__topo--shown {
			--x: 0;
		}

		.brand{
			padding: 30px 15px;
			text-align: center;
		}

		.btn-search{
			display: none;
		}
	}
}

