
.menu{
	ul{
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
	}

	a{
		color: inherit;
		transition: 0.4s linear;
	}

	@include media-breakpoint-up(xl) {
		margin-left: auto;
		margin-right: auto;

		ul{
			display: flex;

			li + li{
				margin-left: 60px;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		a:hover{
			text-decoration: none;
		}

		.active a,
		a:hover{
			color: $red;
		}
	}

	@include media-breakpoint-only(lg) {
		margin-left: auto;
		margin-right: auto;

		ul{
			display: flex;

			li + li{
				margin-left: 40px;
			}
		}
	}

	@include media-breakpoint-only(md) {
		padding: 0 15px;

		ul{
			display: flex;
			justify-content: space-between;
		}

		a:hover{
			text-decoration: none;
		}

		.active a,
		a:hover{
			color: $red;
		}
	}

	@include media-breakpoint-down(sm) {
		a{
			display: block;
			padding: 10px 15px;

			&:hover{
				text-decoration: none;
				background: #EEE;
			}
		}

		.active a,
		.active a:hover {
			background: #F6000E;
			color: #FFF;
		}
	}
}
