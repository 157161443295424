@font-face {
	font-family: 'amiri-regular';
	font-display: swap;
	src: url('../fonts/amiri-regular/amiri-regular.eot');
	src: url('../fonts/amiri-regular/amiri-regular.eot?#iefix') format('embedded-opentype'),
		 // url('../fonts/amiri-regular/amiri-regular.woff2') format('woff2'),
 		 url('../fonts/amiri-regular/amiri-regular.woff') format('woff'),
		 url('../fonts/amiri-regular/amiri-regular.ttf') format('truetype'),
		 url('../fonts/amiri-regular/amiri-regular.svg#amiri-regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'amiri-bold';
	font-display: swap;
	src: url('../fonts/amiri-bold/amiri-bold.eot');
	src: url('../fonts/amiri-bold/amiri-bold.eot?#iefix') format('embedded-opentype'),
		 // url('../fonts/amiri-bold/amiri-bold.woff2') format('woff2'),
 		 url('../fonts/amiri-bold/amiri-bold.woff') format('woff'),
		 url('../fonts/amiri-bold/amiri-bold.ttf') format('truetype'),
		 url('../fonts/amiri-bold/amiri-bold.svg#amiri-bold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'playfair_displayregular';
	font-display: swap;
    src: url('#{$fonts}/PlayfairDisplay-Regular/playfairdisplay-regular-webfont.eot');
    src: url('#{$fonts}/PlayfairDisplay-Regular/playfairdisplay-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/PlayfairDisplay-Regular/playfairdisplay-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/PlayfairDisplay-Regular/playfairdisplay-regular-webfont.woff') format('woff'),
         url('#{$fonts}/PlayfairDisplay-Regular/playfairdisplay-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/PlayfairDisplay-Regular/playfairdisplay-regular-webfont.svg#playfair_displayregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratbold';
	font-display: swap;
    src: url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.eot');
    src: url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;

}
