.rodape{
	overflow: hidden;
}

.rodape-internas{
	color: #FFF;
	background: #000;
}

.rodape__wrapper{
	padding-top: 33px;
	padding-bottom: 42px;

	.container{
		@include media-breakpoint-up(xl) {
			display: grid;
			grid-template-columns: 280px 194px 300px 1fr;
			grid-column-gap: 30px;
		}

		@include media-breakpoint-only(lg) {
			display: grid;
			grid-template-columns: 200px 150px 1fr 1fr;
			grid-column-gap: 30px;
		}

		@include media-breakpoint-only(md) {
			display: grid;
			grid-template-columns: 130px 1fr 1fr;
			grid-column-gap: 30px;

			.rodape__sobre{
				grid-column: span 3;
				text-align: center;
				margin-bottom: 30px;
			}
		}

		@include media-breakpoint-down(sm) {
			& > *,
			.ultima__noticia{
				margin-top: 30px;
			}
		}
	}
}

.rodape__sobre{
	.brand{
		margin-bottom: 20px;
		text-align: center;
	}

	@include media-breakpoint-up(xl) {
		width: 100%;
		max-width: 190px;
	}
}

.rodape__contato{
	.media + .media {
		margin-top: 20px;
	}

	.media-body{
		max-width: calc(100% - 30px);
		margin-left:auto;
		align-self: center;
	}

	a.tel{
		display: inline-block;
		white-space: nowrap;
	}
}

.rodape__mapa {
	ul{
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;

		.active a,
		a:hover{
			color: $red;
		}
	}
}

.rodape__news{


	.ultima__noticia{
		font-size: 15px;

	}
	.foto{
		width: 42px;
		min-width: 42px;
	}

	.media-body{
		max-width: calc(100% - 52px);
		margin-left: 10px
	}

	.publicacao{
		font-size: 13px;
	}

	.newsletter{
		margin-bottom: 10px
	}

	.form-control{
		background: #EEEEEE;
		border-color: #EEEEEE;
	}
}

.creditos{
	background: #000;
	color: #FFF;
	font-size: 15px;
	text-align: center;
	padding: 23px 0;

	svg{
		width: toEm(38,15);
		height: toEm(20,15);
	}

	a:hover{
		color: $red;
		text-decoration: none;
	}
}
