.search{
	position: fixed;
	left: 50%;
	top: 0;
	width: calc(100% - 30px);
	max-width: 400px;
	transform: translate(-50%, var(--y, -100%));
	color: #FFF;
	text-align: center;
	transition: all 0.6s linear;
	z-index: 1000;

	&.search--shown{
		--y: 80px;
	}

	.form-control,
	.btn{
		border-radius: 0;
	}
}
