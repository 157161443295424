@import "_mobile__controls.scss";
@import "_main__topo.scss";
@import "_menu.scss";
@import "_redes__sociais.scss";
@import "_search.scss";

.topo{
	background: rgba(#FFF,.6);

	@include media-breakpoint-up(xl) {
		&:not(.topo-internas) {
			position: absolute;
			width: 100%;
			left: 0;
			top: 0;
			z-index: 999;
		}
	}

	@include media-breakpoint-down(sm) {
		background: #FFF;
		position: sticky;
		top: 0;
		z-index: 999;
		box-shadow: 0 0 8px rgba(#000,.4);
	}

	&.topo-internas{
		box-shadow: 0 0 8px rgba(#000,.4);
	}
}

.backdrop{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(#000, .8);
	animation: fadeIn 0.6s  linear;
	z-index: 999;
}
