.md__form{
	position: relative;
	font-family: 'amiri-bold';

	.md__label{
		position: absolute;
		font-size: 1rem;
		top: calc(0.325rem + 1px);
		left:0.75rem;
		transition: all 0.3s linear;
	}

	.md__input{
		background: #EEEEEE;
		border-color: #EEEEEE;
		box-shadow: none;

		&::placeholder{
			color: transparent;
		}
	}

	.md__input:focus ~ .md__label,
	.md__input:not(:placeholder-shown) ~ .md__label,
	&.focus .md__label{
		font-size: toRem(12);
		top: 0;
		transform: translateY(-50%);
	}
}

.md__file__form{
	position: relative;

	font-family: 'amiri-bold';

	.md__label{
		position: absolute;
		font-size: 1rem;
		top: calc(0.325rem + 1px);
		left:0.75rem;
		transition: all 0.3s linear;
	}

	&.focus .md__label{
		font-size: toRem(12);
		top: 0;
		transform: translateY(-50%);
	}

	.md__label__output{
		display: flex;
		align-items: center;
		overflow: hidden;
		text-overflow: ellipsis;
		@extend .form-control;
		background: #EEE;
		border-color: #EEE;
	}

	.md__input__file {
		display: none;
	}
}

.md__checkbox{
	font-family: 'amiri-bold';

	.md__checkbox__label{
		display: inline-block;
		grid-template-columns: min-content 1fr;
		line-height: 1.5em;
		cursor: pointer;
		position: relative;
		padding-left: calc(1em + 7px);
		min-height: 1em;

		&:before{
			content:'';
			display: flex;
			border: 1px solid #000;
			width: 1em;
			height: 1em;
			border-radius: 4px;
			color: $success;
			align-items: center;
			justify-content: center;
			padding-left: 4px;
			position: absolute;
			left: 0;
			top: 0.1em;
		}
	}

	.md__checkbox__input{
		display: none;
	}

	.md__checkbox__input:checked ~ .md__checkbox__label:before{
		content: fa-content($fa-var-check);
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
	}
}
