.paginacao{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	font-family: 'montserratbold';

	&.paginacao__start{
		justify-content: flex-start;
	}

	&.paginacao__center{
		justify-content: center;
	}

	&.paginacao__end{
		justify-content: flex-end;
	}

	&.paginacao__between{
		justify-content: space-between;
	}

	.page__item {
		display: inline-block;
		padding: 5px 10px;
		border: 2px solid #000;
		color: inherit;
		margin:0 3px;
	}

	a.page__item:hover{
		text-decoration: none;
		background: #000;
		color: #FFF;
	}

	span.page__item--active{
		background: #000;
		color: #FFF;
	}
}
