#banner{
	position: relative;
	overflow: hidden;

	.carousel-inner{
		width: 160%;
		max-width: 1920px;
		left: 50%;
		transform: translateX(-50%);
	}

	@include media-breakpoint-up (xl) {
		.carousel-indicators {
			flex-direction: column;
			margin:0;
			right: auto;
			left:calc(50% + #{map-get($container-max-widths, xl) / 2});
			transform: translateX(-100%);
			justify-content: center;
			width: auto;
			height: 100%;
		}
	}

	@include media-breakpoint-only (lg) {
		.carousel-indicators {
			flex-direction: column;
			margin:0;
			right: auto;
			left:calc(50% + #{map-get($container-max-widths, lg) / 2});
			transform: translateX(-100%);
			justify-content: center;
			width: auto;
			height: 100%;
		}
	}

	.carousel-indicators {
		li {
			width: 16px;
			height: 16px;
			border: 2px solid #FFF;
			background: #FFF;
			opacity: 1;
			border-radius: 50%;
			margin:5px;

			&.active{
				background: transparent;
				animation: rubberBand 0.6s linear;
			}
		}
	}
}


.chamadas__index{
	background: #000;
	color: #FFF;
	overflow: hidden;

	.container{
		display: grid;
		grid-column-gap: 30px;

		@include media-breakpoint-up(xl) {
			grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
		}

		@include media-breakpoint-only(lg) {
			$min: (map-get($container-max-widths, lg) - 60) / 2;
			grid-template-columns: repeat(auto-fit, minmax($min, 1fr));
		}

		@include media-breakpoint-only(md) {
			$min: (map-get($container-max-widths, md) - 60) / 2;
			grid-template-columns: repeat(auto-fit, minmax($min, 1fr));
		}

		@include media-breakpoint-only(sm) {
			$min: (map-get($container-max-widths, sm) - 60) / 2;
			grid-template-columns: repeat(auto-fit, minmax($min, 1fr));
		}
	}
}

.chamada__index{
	padding: 30px 0;
	border-bottom: 6px solid transparent;
	transition: border 0.3s linear;

	&:hover{
		border-color: #333;
	}
}

.chamada__index__foto{
	margin-bottom: 15px;
}

.chamada__index__title{
	font-size: 18px;
	font-family: 'amiri-bold', sans-serif;
}
