.page__area__atuacao{
	padding-bottom: 55px;

	textarea.md__input{
		min-height: 84px;
	}
}

.area__atuacao{
	width: 100%;
	max-width: 350px;
	margin-left:auto;
	margin-right:auto;
	text-align: center;

	a:hover{
		color: inherit;
		text-decoration: none;
		color: $red;
	}
}

.area__atuacao__foto{
	margin-bottom: 10px;
}

.area__atuacao__title{
	margin-bottom: 10px;
	@include line-clamp;
	font-family: 'amiri-bold';
	font-size: 18px;
}

.area__atuacao__description{
	@include line-clamp(3);
}
