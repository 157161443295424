.topo .redes__sociais{
	display: flex;
	font-size: 1rem;

	a{
		display: inline-flex;
		margin: 0 5px;
		padding: .325rem 0;

		transition: 0.4s linear;

		&:hover{
			text-decoration: none;
			color: $red;
		}
	}

	@include media-breakpoint-down(sm) {
		justify-content: center;
		padding-top: 15px;
		font-size: 25px;
		border-top:1px solid rgba(#000,0.1)
	}
}
