.mobile__controls{
	@include media-breakpoint-down(sm) {
		display: grid;
		grid-template-columns: 50px 1fr 50px;
		grid-column-gap: 10px;
		min-height: 68px;
	}

	@include media-breakpoint-up(md) {
		display: none;
	}

	.brand{
		padding: 10px 0;
		width: 100%;
		max-width: 210px;
		align-self: center;
	}

	.btn{
		border-radius: 0;
		font-size: 20px;

		&:hover{
			background: rgba(#000,.2);
		}
	}
}
