.internas{
	padding-top: 55px;
	overflow: hidden;
	position: relative;

	.breadcrumb{
		display: none;
	}
}

.page-header{
	margin-bottom: 30px;

	h1{
		font-family: 'playfair_displayregular', sans-serif;
		text-align: center;

		@include media-breakpoint-up(md) {
			font-size: 55px;
		}
	}
}


.btn-enviar{
	min-width: 250px;
	padding: 0.75rem 1.5rem;
	background: #000;
	color: #FFF;
	font-family: 'amiri-bold';
	overflow: hidden;
	perspective: 3px;
	position: relative;

	&:before,
	&:after{
		content:'';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(#FFF,.1);
		left:0;
		top: 0;
		clip-path: circle(var(--w, 0) at var(--x) var(--y));
		transition: all 0.4s linear;
	}

	&:before{
		--x: 0px;
		--y: 0px;
	}

	&:after{
		--x: 100%;
		--y: 0px;
	}

	&:hover{
		color: #FFF;
		--w: 300%;
	}
}
