.page__contato{
	padding-bottom: 80px;

	.title-ordenal{
		padding-left:3em;
		position: relative;
		font-family: 'amiri-bold';

		.order{
			display: block;
			font-size: 16px;
			color: #FFBF00;
		}

		&:before{
			content: '';
			display: block;
			width: 2em;
			height:0.2em;
			background: currentcolor;
			position: absolute;
			left: 0;
			top: calc(16px * 1.4 + calc(1em * 1.4 * 0.4));
			transform: translateY(-50%);
		}
	}

	form{
		border-top:5px solid #000;
		padding-top:40px;
		margin-bottom:60px;
	}


	a:hover{
		color: $red;
		text-decoration: none;
	}

	.md-form{
		position: relative;
		margin-bottom: 15px;

		.form-control{
			border-top:none;
			border-right:none;
			border-left:none;
			background-color: transparent;
			background-image: linear-gradient(to right, var(--context, $success) 0%, var(--context, $success) 50%, #ccc 50%, #ccc 100%);
			background-size: 200% 3px;
			background-position: var(--x,right) bottom;
			background-repeat: no-repeat;
			transition: background 0.3s linear;
			box-shadow: none !important;

			&::placeholder{
				color: transparent;
			}
		}

		label{
			position: absolute;
			top: calc(0.325rem + 1px);
			left: 0.75rem;
			font-size: 1rem;
			transition: all 0.3s linear;
			color: var(--context, inherit);
		}

		&.focus,
		.form-control:not(:placeholder-shown),
		.form-control:focus{
			--x: left;
		}

		&.focus label,
		.form-control:not(:placeholder-shown) ~ label,
		.form-control:focus ~ label {
			top: 0;
			font-size: toRem(12);
			transform: translateY(-50%);
		}

		&.has-success {
			--context: #{$success};
		}

		&.has-error {
			--context: #{$danger};
		}

		.md__validation__msg{
			margin-top: 10px;
			color: var(--context, inherit);
			animation: rubberBand 1s linear;
			display: inline-block;
			font-family: sans-serif;

			& + .md__validation__msg{
				margin-left: 10px;
			}

			&.md__validation__msg__inverse{
				background: var(--context, #333);
				color: #FFF;
				padding: 5px;
				border-radius: 4px;
			}
		}
	}

	.btn-enviar{
		display: inline-flex;
		align-items:center;
		border-radius:0;

		@include button-variant(#FFF, #FFF);

		&:before{
			content:'';
			width:1.5em;
			height: 0.25em;
			background: currentcolor;
			display: inline-block;
			margin-right: 7px;
		}
	}

	.dados-contato{
		a{
			color: inherit;

			&:hover{
				color: #FFBF00;
			}
		}
	}
}
